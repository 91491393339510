<template>
  <div
    class="text-center"
    style="min-height: 100%; padding-top: 50px"
  >
    <h1>{{ msg }}</h1>

    <router-link to="/">
      Home
    </router-link>
  </div>
</template>

<script>

export default {
    name: 'Error',
    components: {
    },
    props: [ 'msg' ],
    data() {
        return {
        };
    },
    computed: {
    },
    async mounted(){
        console.log( "Error MOUNTED" );
        console.log( this.$route );

    }
};
</script>
